
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';

export default defineComponent({
    components: { FormsReportTypes },
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            item: {
                translations: {
                    name: {},
                },
            },
        };
    },
    methods: {
        createItem(): void {
            this.post('product-categories', {
                ...this.item,
                name: this.item.translations.name,
            }).then(() => {
                this.$router.push({ name: 'products.categories.list' });
                this.$emit('reload');
            });
        },
    },
});
